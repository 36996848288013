import {getLegendItems} from '../../../../../selectors/seating'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {LegendRuntimeProps, LegendOwnProps} from './interfaces'
import {Legend as Presentation} from './legend'

const mapRuntime = ({state}: DetailsPageAppProps): LegendRuntimeProps => ({
  legendItems: getLegendItems(state),
})

export const Legend = connect<LegendOwnProps, LegendRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
