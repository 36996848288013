import {isExternal, isWithoutRegistration} from '@wix/wix-events-commons-statics'
import {isEditor} from '../../../commons/selectors/environment'
import {State} from '../types'
import {hasGuestsGoing} from './event'
import {isMembersAvatarsVisibilityEnabled} from './settings'

export const isMembersVisible = (state: State) =>
  !isExternal(state.event) && state.membersAreaEnabled && isMembersAvatarsVisibilityEnabled(state.component.settings)

export const isMembersVisibleWithGuests = (state: State) =>
  isMembersVisible(state) && (hasGuestsGoing(state) || isEditor(state)) && !isWithoutRegistration(state.event)
