import {withTranslation} from '@wix/yoshi-flow-editor'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {getPlaces, getPlacesInBasket, getSelectedPlace, isShowAccessibilityMode} from '../../../../selectors/seating'
import {PlanOwnProps, PlanRuntimeProps} from './interfaces'
import {Plan as Presentation} from './plan'

const mapRuntime = ({
  state: {
    seating: {plan, planReservations},
  },
  state,
}: DetailsPageAppProps): PlanRuntimeProps => ({
  plan,
  planReservations,
  selectedPlace: getSelectedPlace(state),
  placesInBasket: getPlacesInBasket(state),
  places: getPlaces(state),
  showAccessibilityMode: isShowAccessibilityMode(state),
})

export const Plan = connect<PlanOwnProps, PlanRuntimeProps>(mapRuntime)(withTranslation()(Presentation))

export * from './interfaces'
