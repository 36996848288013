import React from 'react'
import classNames from 'classnames'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {isActive} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {usePageActions, usePageState} from '../../runtime-context/context'
import {SectionButton} from '../section-button'
import s from './recurring-dates-button.scss'

interface RecurringDatesButtonProps {
  className?: string
}

export const RecurringDatesButton = ({className}: RecurringDatesButtonProps) => {
  const {t} = useTranslation()
  const eventId = usePageState(state => state).event.id
  const isActiveEvent = usePageState(state => isActive(state.event))
  const {openRecurringEventsModal} = usePageActions()

  return (
    <SectionButton
      onClick={() => openRecurringEventsModal(eventId)}
      className={classNames(s.root, className)}
      dataHook={DH.RECURRING_DATES_BUTTON}
    >
      {isActiveEvent ? t('recurring.selectDatesButton') : t('recurring.selectUpcomingDatesButton')}
    </SectionButton>
  )
}
