import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../../classnames.scss'
import {MembershipOffers} from '../../../event-details/membership-offers'
import {MobileTicket} from '../../../event-details/tickets-picker/ticket'
import {TotalPrice} from '../../../event-details/tickets-picker/total-price'
import {RegistrationButtonSkin} from '../../../registration-buttons/registration-button-skin'
import {usePrevious} from '../../../../hooks/use-previous'
import s from './mobile-tickets-picker.scss'
import {MobileTicketsPickerProps} from '.'

export const MobileTicketsPicker = ({
  title,
  buttonStyle,
  tickets,
  selectTicket,
  t,
  checkoutButtonDisabled,
  showPaidPlans,
  checkout,
  resetCouponCode,
  clearInvoice,
  clearCheckout,
  openCheckoutUnavailable,
  reservationUnavailable,
  withSeatingPlan,
  openSeatingPlan,
}: MobileTicketsPickerProps) => {
  const prevReservationUnavailable = usePrevious(reservationUnavailable)

  React.useEffect(() => {
    resetCouponCode()
    clearInvoice()
    clearCheckout()
  }, [resetCouponCode, clearInvoice, clearCheckout])

  React.useEffect(() => {
    if (prevReservationUnavailable !== reservationUnavailable && reservationUnavailable) {
      openCheckoutUnavailable()
    }
  }, [reservationUnavailable, prevReservationUnavailable, openCheckoutUnavailable])

  const handleOnClick = () => {
    if (withSeatingPlan) {
      return openSeatingPlan()
    }

    return checkout()
  }

  return (
    <div data-hook={DH.TICKETS_PICKER} className={classNames(c.evBackgroundColor, s.mobileCheckoutWrapper)}>
      {showPaidPlans && <MembershipOffers />}
      <div className={classNames(c.evSubtitlesFont, c.evSubtitlesColor, s.mobileCheckoutHeader)}>{title}</div>
      <div className={classNames(c.evTicketDividerColor, s.mobileCheckoutDivider)} />
      {tickets.map(ticket => (
        <MobileTicket onChange={selectTicket} ticket={ticket} key={ticket.id} />
      ))}
      {withSeatingPlan ? null : <TotalPrice />}
      <RegistrationButtonSkin
        secondary={true}
        dataHook={DH.CHECKOUT}
        minWidth="100%"
        disabled={!withSeatingPlan && checkoutButtonDisabled}
        buttonStyle={buttonStyle}
        onClick={handleOnClick}
        text={withSeatingPlan ? t('ticketsPicker_selectTickets') : t('checkout')}
      />
    </div>
  )
}
