import React from 'react'
import classNames from 'classnames'
import {InvoiceBreakdown} from '../../../../invoice-breakdown'
import {getInvoiceItemsWithTicketInfo} from '../../../../../selectors/order-success'
import c from '../../../../classnames.scss'
import s from './invoice.scss'
import {TicketInfo} from './ticket-info'
import {PlaceInfo} from './place-info'
import {InvoiceProps} from '.'

export const Invoice = ({invoice: {items}, tickets, hasSeatingPlan}: InvoiceProps) => (
  <div className={classNames(c.evTextFont, c.formLabelsColor)}>
    {getInvoiceItemsWithTicketInfo(items, tickets).map((item, index) =>
      hasSeatingPlan ? <PlaceInfo key={index} item={item} /> : <TicketInfo key={index} item={item} />,
    )}
    <InvoiceBreakdown containerClass={s.container} />
  </div>
)
