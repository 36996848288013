import {
  getCurrency,
  getFormattedMoney,
  WIX_EVENTS_TICKET_DEFINITION_FQDN,
  PlaceWithTicketInfo,
  getEventTicketLimitPerOrder,
} from '@wix/wix-events-commons-statics'
import {createSelector} from 'reselect'
import {PlanReservations} from '@wix/ambassador-seating-v1-seating-plan/types'
import {State} from '../types'

export const getTicketDefinitionByExternalId = (tickets: wix.events.ticketing.TicketDefinition[], externalId: string) =>
  tickets.find(ticketDef => ticketDef.id === externalId.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''))

export const getPlaces = (state: State) => state.seating.places

export const getSelectedFormattedPrice = (state: State) => {
  const selectedPrice = state.seating.selectedPrice
  if (selectedPrice) {
    return getFormattedMoney({amount: selectedPrice, currency: getCurrency(state.event)})
  }
}

export const getSelectedZone = (state: State) => state.seating.selectedZone

export const getPlanReservations = (state: State) => state.seating.planReservations.reservations

export const getPlacesInBasket = createSelector(getPlaces, places => places.filter(place => place.inBasket))

export const getFilteredPlaces = createSelector(
  getPlaces,
  getSelectedFormattedPrice,
  getSelectedZone,
  getPlanReservations,
  (places, price, zone, reservations) => {
    places = places.filter(place => place.capacity - reservations[place.id]?.occupied ?? 0)
    if (price) {
      places = places.filter(place => place.ticketPrice === price)
    }

    if (zone) {
      places = places.filter(place => place.elementId === Number(zone))
    }

    return places
  },
)

export const getSelectedTicketsWithPlaces = createSelector(getPlaces, places =>
  places
    .filter(place => place.inBasket)
    .reduce((acc, place) => [...acc, ...new Array(place.quantity).fill(place)], [] as PlaceWithTicketInfo[]),
)

export const getSelectedPlace = createSelector(getPlaces, places => places.find(place => place.selected))

export const getPlaceQuantity = createSelector(
  (places: PlaceWithTicketInfo[], id: string): number => getPlaceInfo(places, id)?.quantity ?? 0,
  places => places,
)

export const getPlaceInfo = (places: PlaceWithTicketInfo[], id: string): PlaceWithTicketInfo | undefined =>
  places.find(place => place.id === id)

export const isTicketLimitReached = (state: State) =>
  getEventTicketLimitPerOrder(state.event) === getSelectedTicketsWithPlaces(state).length

export const getLegendItems = (state: State) =>
  state.seating.plan.categories
    .filter(category => Boolean(category.places.length))
    .map(({externalId, config: {color}}) => ({
      color,
      price: getFormattedMoney(getTicketDefinitionByExternalId(state.tickets, externalId).price),
    }))

export const calculatePlacesStock = (places: PlaceWithTicketInfo[], planReservations: PlanReservations) =>
  places.reduce((acc, place) => {
    acc[place.id] = place.capacity - place.quantity - planReservations.reservations[place.id].occupied
    return acc
  }, {} as Record<string, number>)

export const isShowAccessibilityMode = (state: State) => state.seating.showAccessibilityMode
