import React from 'react'
import {Button, PRIORITY} from 'wix-ui-tpa/Button'
import classNames from 'classnames'
import {classes} from './join-button.st.css'
import {JoinButtonProps} from '.'

export const JoinButton: React.FC<JoinButtonProps> = ({className, onClick, dataHook, children}) => (
  <Button
    upgrade
    priority={PRIORITY.secondary}
    className={classNames(classes.root, className)}
    onClick={onClick}
    data-hook={dataHook}
  >
    {children}
  </Button>
)
