import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {getFormClassName} from '../../../selectors/form'
import {FormButton} from '../../form-button'
import fs from '../../form/form.scss'
import {FormSteps, Step} from '../../form-steps'
import {CouponInput} from '../../coupon/coupon-input'
import {Checkout} from '../index'
import {MembershipSelector} from '../membership-selector'
import {ReservationTimer} from '../reservation-timer'
import c from '../../classnames.scss'
import {CheckoutSummary} from './checkout-summary'
import {MobileCheckoutProps} from './interfaces'
import s from './mobile-checkout.scss'

export const MobileCheckout = ({
  formPageLoaded,
  closeNativeModal,
  openTicketsDetailsModal,
  buttonStyle,
  reservationExpiration,
  invoice,
  t,
  isMobile,
  openTimeExpiredModal,
}: MobileCheckoutProps) => {
  React.useEffect(() => {
    formPageLoaded(true)
    closeNativeModal()
  }, [formPageLoaded, closeNativeModal])

  const isCheckoutReady = (steps: Step[]) => steps.every(step => step.completed)

  const openTicketDetails = () => openTicketsDetailsModal()

  return (
    <Checkout t={t}>
      {(steps: Step[], currentStep: string, pay: Function) => (
        <div className={classNames(s.container, c.formBackgroundColor, getFormClassName(buttonStyle))}>
          <div className={fs.summaryBlock}>
            <ReservationTimer
              expires={reservationExpiration}
              onExpire={openTimeExpiredModal}
              t={t}
              isMobile={isMobile}
            />
          </div>
          <CouponInput t={t} />
          <MembershipSelector />
          <CheckoutSummary invoice={invoice} onViewAllItems={openTicketDetails} t={t} />
          <FormSteps currentStep={currentStep} steps={steps} t={t} />
          {isCheckoutReady(steps) && (
            <div className={s.button}>
              <FormButton
                text={t('mobile.checkout.placeOrder')}
                type="submit"
                dataHook={DH.CHECKOUT_BUTTON}
                onClick={pay}
              />
            </div>
          )}
        </div>
      )}
    </Checkout>
  )
}
