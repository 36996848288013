import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {FormButton} from '../../form-button'
import {PaymentProps} from './interfaces'
import {PaymentWidget} from './payment-widget'
import s from './payment.scss'

export const Payment: React.FC<PaymentProps> = ({
  paymentRef,
  editing,
  eventId,
  order,
  reservationId,
  onSubmit,
  openCantCompletePaymentModal,
  t,
  isMobile,
  locale,
  isSite,
  isPreview,
  visitorId,
  msid,
  instanceId,
  siteOwnerId,
  navigateToOrder,
  primaryLanguage,
  instance,
  siteStyles,
  isSignedInUser,
  lang,
  updateLayout,
}) => (
  <div className={s.container}>
    <PaymentWidget
      locale={locale}
      ref={paymentRef}
      eventId={eventId}
      snapshotId={order?.snapshotId}
      reservationId={reservationId}
      isMobile={isMobile}
      isPreview={isPreview}
      openCantCompletePaymentModal={openCantCompletePaymentModal}
      navigateToOrder={navigateToOrder}
      isSite={isSite}
      visitorId={visitorId}
      msid={msid}
      instanceId={instanceId}
      primaryLanguage={primaryLanguage}
      siteOwnerId={siteOwnerId}
      externalSubmitButton
      order={order}
      lang={lang}
      instance={instance}
      siteStyles={siteStyles}
      isSignedInUser={isSignedInUser}
      updateLayout={updateLayout}
    />
    {editing && (
      <div className={s.button}>
        <FormButton
          dataHook={DH.PAYMENT_METHOD_SUBMIT}
          type="submit"
          text={t('mobile.checkout.placeOrder')}
          onClick={() => onSubmit()}
        />
      </div>
    )}
  </div>
)
