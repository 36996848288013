import {State} from '../types'

export const getReservationId = (state: State) => state.reservation.data?.id

export const getReservationExpiration = (state: State) => state.reservation.data?.expires

export const getReservationError = (state: State) => state.reservation.error

export const getReservationTicketIds = (state: State) => []
// TODO check this, API does not return reservations at all
// state.reservation.data?.reservations?.map(reservation => reservation.ticket.id) ?? []

export const isPlaceReservedError = action =>
  action.error?.payload?.details?.applicationError?.code === 'PLACE_RESERVED'
