import {connect} from '../../../runtime-context/context'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {getButtonStyle} from '../../../../selectors/settings'
import {ButtonStyleRuntimeProps, ButtonStyleOwnProps} from './interfaces'
import {ButtonStyle as Presentation} from './button-style'

const mapRuntime = ({state}: DetailsPageAppProps): ButtonStyleRuntimeProps => {
  const componentSettings = state.component.settings
  return {
    buttonStyle: getButtonStyle(componentSettings),
  }
}

export const ButtonStyle = connect<ButtonStyleOwnProps, ButtonStyleRuntimeProps>(mapRuntime)(Presentation)

export * from './interfaces'
