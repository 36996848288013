import {getAboutText} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {isEditor, isMobile} from '../../../../../commons/selectors/environment'
import {getButtonStyle, getTexts} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {isPaidPlansVisible} from '../../../selectors/paid-plans'
import {isGroupActivityVisible} from '../../../selectors/groups'
import {AboutSection as Presentation} from './about-section'
import {AboutSectionOwnProps, AboutSectionRuntimeProps} from './interfaces'

export const mapRuntimeToProps = ({state, host}: DetailsPageAppProps, {t}): AboutSectionRuntimeProps => ({
  updateLayout: host.updateLayout,
  aboutTitleText: getTexts(state.component.settings).aboutTitleText,
  aboutText: getAboutText(state.event),
  readMoreText: getTexts(state.component.settings).readMoreText,
  readLessText: getTexts(state.component.settings).readLessText,
  buttonStyle: getButtonStyle(state.component.settings),
  isEditor: isEditor(state),
  isMobile: isMobile(state),
  readMoreButtonExtraMargin: isPaidPlansVisible(state, t) && !isGroupActivityVisible(state),
  readMoreButtonVisible: state.component.settings.readMoreButtonVisible,
  eventId: state.event.id,
})

export const AboutSection = withTranslation()(
  connect<AboutSectionOwnProps, AboutSectionRuntimeProps>(mapRuntimeToProps)(Presentation),
)
export * from './interfaces'
