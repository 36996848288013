import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import classNames from 'classnames'
import {Button} from 'wix-ui-tpa/Button'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {CloseButton} from '../../../common/close-button'
import {ERROR} from '../../../../../actions/seating'
import {classes} from '../error-modal.st.css'
import {TicketLimitReachedProps} from './interfaces'

export const TicketLimitReached = ({ticketLimitPerOrder, setError}: TicketLimitReachedProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  return (
    <div className={classes.container}>
      <div className={classNames(classes.content, {[classes.desktopContent]: !isMobile})}>
        <CloseButton
          className={classes.closeIcon}
          closeButtonAsButton={false}
          closeModal={() => setError(ERROR.NO_ERROR)}
        />
        <div className={classes.text}>
          <Text className={classNames(classes.title, {[classes.mobileTitle]: isMobile})}>
            {t('seatings_ticketLimitReachedModal_title')}
          </Text>
          <Text className={classes.subtitle}>
            {t('seatings_ticketLimitReachedModal_subtitle', {limit: ticketLimitPerOrder})}
          </Text>
        </div>
        <Button data-hook={DH.TICKET_LIMIT_REACHED_MODAL_BUTTON} onClick={() => setError(ERROR.NO_ERROR)}>
          {t('seatings_ticketLimitReachedModal_button')}
        </Button>
      </div>
    </div>
  )
}
