import * as React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {Divider} from '../../../../common/divider'
import {LabelWithValue} from '../../../../../common/label-with-value'
import c from '../../../../../classnames.scss'
import {getPlaceInfoFromTicket, getTicketPriceText} from '../../../../../../selectors/order-success'
import s from './place-info.scss'
import {PlaceInfoProps} from '.'

export const PlaceInfo = ({item}: PlaceInfoProps) => {
  const {t} = useTranslation()
  const placeInfoClassNames = classNames(c.formLabelsColor, c.evTextFont)
  const {area, table} = getPlaceInfoFromTicket(item.ticket)

  return (
    <>
      <div className={s.container}>
        <div className={s.placeInfoContainer}>
          <LabelWithValue
            label={t('seatings_sector')}
            value={item.ticket.ticketDetails.sectorName}
            valueClassName={placeInfoClassNames}
            labelClassName={placeInfoClassNames}
          />
          {area ? (
            <LabelWithValue
              label={t('seatings_area')}
              value={area}
              valueClassName={placeInfoClassNames}
              labelClassName={placeInfoClassNames}
            />
          ) : (
            <>
              <LabelWithValue
                label={table ? t('seatings_table') : t('seatings_row')}
                value={table || item.ticket.ticketDetails.rowNumber}
                valueClassName={placeInfoClassNames}
                labelClassName={placeInfoClassNames}
              />
              <LabelWithValue
                label={t('seatings_seat')}
                value={item.ticket.ticketDetails.seatNumber}
                valueClassName={placeInfoClassNames}
                labelClassName={placeInfoClassNames}
              />
            </>
          )}
        </div>
        <div className={s.ticketInfoContainer}>
          <div className={s.ticketName}>{item.name}</div>
          <div>{getTicketPriceText(item.price, t)}</div>
        </div>
      </div>
      <Divider />
    </>
  )
}
