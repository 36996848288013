import * as React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {getFormattedPrice, getFormattedMoney} from '@wix/wix-events-commons-statics'
import {SummaryDivider} from '../../summary-divider'
import s from './ticket.scss'
import {TicketProps} from '.'

export const Ticket = ({ticket, currency}: TicketProps) => {
  const {t} = useTranslation()

  const getTicketAriaLabel = () => {
    const name = `${t('ticketsThankYou.ticketName')}: ${ticket.name}`
    const price = t('mobile.thanks.price', {price: getFormattedMoney(ticket.price)})
    const qty = t('mobile.thanks.quantity', {quantity: ticket.orderedCount})
    return `${name}, ${price}, ${qty}`
  }

  return (
    <div data-hook={DH.TICKET_WRAPPER}>
      <div className={s.ticket} data-hook={DH.TICKET} aria-label={getTicketAriaLabel()}>
        <div className={s.name} data-hook={DH.TICKET_NAME}>
          {ticket.name}
        </div>
        <div className={s.singlePrice}>{t('mobile.thanks.price', {price: getFormattedMoney(ticket.price)})}</div>
        <div className={s.row}>
          <div className={s.quantity} data-hook={DH.TICKET_QUANTITY}>
            {t('mobile.thanks.quantity', {quantity: ticket.orderedCount})}
          </div>
          <div className={s.price} data-hook={DH.TICKET_TOTAL_PRICE}>
            {getFormattedPrice(Number(ticket.price.amount) * ticket.orderedCount, currency)}
          </div>
        </div>
      </div>
      <SummaryDivider />
    </div>
  )
}
