import React from 'react'
import {DETAILS_ROUTE} from '../../../../commons/constants/navigation'
import {ReservationState} from '../../../../commons/enums'
import {ChangeRsvp} from '../change-rsvp'
import {EventDetails} from '../event-details'
import {EventDetailsMobile} from '../event-details-mobile'
import {RsvpForm} from '../rsvp-form'
import {LinkExpired} from '../status-screens/link-expired'
import {OrderExpired} from '../status-screens/order-expired'
import {OrderFailed} from '../status-screens/order-failed'
import {OrderSuccess} from '../status-screens/order-success'
import {OrderCanceled} from '../status-screens/order-canceled'
import {ThankYouMessages} from '../status-screens/thank-you-messages'
import {DesktopCheckout} from '../checkout/desktop-checkout'
import {MobileCheckout} from '../checkout/mobile-checkout'
import {OnlineConferencingStatus} from '../status-screens/online-conferencing'
import {RouterProps} from './interfaces'

export const Router = ({route, mobile, reservationState, isTicketed, isEditor}: RouterProps) => {
  const getCheckoutComponent = () => {
    if (isEditor && !isTicketed) {
      return null
    }

    return mobile ? <MobileCheckout /> : <DesktopCheckout />
  }

  const getPickTicketsComponent = () => {
    if (mobile) {
      return <EventDetailsMobile />
    } else {
      return <EventDetails />
    }
  }

  switch (route) {
    case DETAILS_ROUTE.DETAILS:
      return mobile ? <EventDetailsMobile /> : <EventDetails />
    case DETAILS_ROUTE.PICK_TICKETS:
      return getPickTicketsComponent()
    case DETAILS_ROUTE.TICKET_FORM:
      return getCheckoutComponent()
    case DETAILS_ROUTE.FORM:
      return <RsvpForm />
    case DETAILS_ROUTE.CHECKOUT:
      return <MobileCheckout />
    case DETAILS_ROUTE.TEMPLATE_ORDER:
      return <OrderSuccess />
    case DETAILS_ROUTE.ORDER:
      switch (reservationState) {
        case ReservationState.SUCCESS:
        case ReservationState.PENDING:
          return <OrderSuccess />
        case ReservationState.ERROR:
          return <OrderFailed />
        case ReservationState.CANCELED:
          return <OrderCanceled />
        case ReservationState.EXPIRED:
        default:
          return <OrderExpired />
      }
    case DETAILS_ROUTE.ONLINE_CONFERENCING:
      return <OnlineConferencingStatus />
    case DETAILS_ROUTE.LINK_EXPIRED:
    case DETAILS_ROUTE.NOT_FOUND:
      return <LinkExpired />
    case DETAILS_ROUTE.CHANGE_RSVP:
      return <ChangeRsvp />
    case DETAILS_ROUTE.THANK_YOU_MESSAGES:
      return <ThankYouMessages />
    default:
      return <div>404</div>
  }
}
