import {handleSpaceKeyDown} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../classnames.scss'
import s from './policy-checkbox.scss'
import {PolicyCheckboxProps} from './interfaces'
import {PolicyList} from './policy-list'

export const PolicyCheckbox = ({onChange, value, label, checked, policies, onPolicyClick}: PolicyCheckboxProps) => {
  const getPolicyAriaLabel = () => `${label} ${policies.map(({name}) => name).join(', ')}`

  return (
    <div className={classNames(s.labelContainer, s.wrapper)}>
      <div className={s.checkboxWrapper}>
        <span
          tabIndex={0}
          role="checkbox"
          aria-checked={checked}
          onClick={() => onChange(value)}
          onKeyDown={handleSpaceKeyDown(onChange)}
          aria-label={getPolicyAriaLabel()}
        >
          <input
            className={s.input}
            data-hook={DH.CHECKBOX_INPUT}
            type="checkbox"
            value={value}
            checked={checked}
            onChange={() => onChange(value)}
          />
          <label className={s.wrapper}>
            <span
              className={classNames(c.formBorderColor, s.checkbox, {[s.checkedCheckbox]: checked})}
              data-hook={DH.CHECKBOX}
            />
          </label>
        </span>
      </div>
      <div>
        <span
          className={classNames(c.formLabelsFont, c.formLabelsColor, s.checkboxLabel)}
          data-hook={DH.CHECKBOX_LABEL}
        >
          {label}
        </span>
        <span className={classNames(c.formLabelsFont, c.formLabelsColor, s.checkboxLabel)}>
          <PolicyList policies={policies} onPolicyClick={onPolicyClick} />
        </span>
      </div>
    </div>
  )
}
