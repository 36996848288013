import {getEventId, isAssignedTickets} from '@wix/wix-events-commons-statics'
import {isPreview, isSite} from '../../../commons/selectors/environment'
import {isTemplate} from '../../../commons/selectors/instance'
import {CHECKOUT_STEPS, FormStep} from '../constants/constants'
import {
  getBuyerDetails,
  getCurrentStep,
  isStepCompleted,
  isStepVisible,
  isValidPaymentAdded,
  getTicketsDetails,
  isAllTicketDetailsValid,
} from '../selectors/checkout'
import {getInvoice, isOrderCompleted} from '../selectors/placed-order'
import {getReservationId} from '../selectors/reservation'
import {getSortedSelectedTickets} from '../selectors/selected-tickets'
import {GetState, RegFormData} from '../types'
import {getSelectedPaidPlan} from '../selectors/paid-plans'
import {callAPI, createActions} from '../../../commons/actions/data-action-helper'
import {couponSubmitted, getCouponCode} from '../selectors/invoice'
import {hasAgreedWithPolicies, hasPolicies} from '../selectors/policies'
import {createAsyncAction} from '../services/redux-toolkit'
import {ensureLoginForMembersOnly} from './members'
import {openCantCompletePaymentModal} from './modals'
import {placeOrder, updateOrder} from './placed-order'
import {reserveTickets} from './reservation'
import {couponApplied} from './coupon'
import {navigateToTemplateOrder} from './navigation'

export const GET_DISCOUNT = createActions('GET_DISCOUNT')
export const NEXT_FORM_CLICKED = 'NEXT_FORM_CLICKED'
export const SET_BUYER_DETAILS = 'SET_BUYER_DETAILS'
export const SET_TICKET_DETAILS = 'SET_TICKET_DETAILS'
export const NEXT_STEP = 'NEXT_STEP'
export const EDIT_STEP = 'EDIT_STEP'
export const SET_VALID_PAYMENT_ADDED = 'SET_VALID_PAYMENT_ADDED'
export const USE_BUYER_DETAILS = 'USE_BUYER_DETAILS'
export const SET_EXPANDED_TICKET_INDEX = 'SET_EXPANDED_TICKET_INDEX'
export const CLEAR_CHECKOUT = 'CLEAR_CHECKOUT'

export const nextFormClicked = () => ({type: NEXT_FORM_CLICKED})

export const setExpandedTicketIndex = (index: number) => ({type: SET_EXPANDED_TICKET_INDEX, payload: {index}})

export const setValidPaymentAdded = (validPaymentAdded: boolean) => ({
  type: SET_VALID_PAYMENT_ADDED,
  payload: {validPaymentAdded},
})

export const getDiscount = (eventId: string, reservationId: string) => async (
  dispatch: Function,
  getState: GetState,
) => {
  const state = getState()
  const couponCode = couponSubmitted(state) ? getCouponCode(state) : ''
  const {benefitId, planOrderId} = getSelectedPaidPlan(state) ?? {}

  if (reservationId) {
    const {invoice} = await dispatch(callAPI(GET_DISCOUNT, eventId, reservationId, couponCode, benefitId, planOrderId))

    if (invoice?.discount) {
      dispatch(couponApplied())
    }
  }
}

export const checkout = createAsyncAction('CHECKOUT', async (_, {getState, dispatch}) => {
  const state = getState()
  const event = state.event

  if (await dispatch(ensureLoginForMembersOnly())) {
    return dispatch(reserveTickets(getEventId(event), getSortedSelectedTickets(state)))
  }
})

export const placeOrderCheckout = (buyer: RegFormData, guests?: RegFormData[]) => (
  dispatch: Function,
  getState: GetState,
) => {
  const state = getState()
  const event = state.event
  const eventId = getEventId(event)
  const placedOrder = state.placedOrder.order

  if (isPreview(state)) {
    dispatch(openCantCompletePaymentModal())
  }

  if (isSite(state)) {
    const normalizedBuyer = normalizeFormData(buyer)
    const normalizedGuests = guests ? guests.map(normalizeFormData) : undefined

    if (isOrderCompleted(placedOrder)) {
      return dispatch(updateOrder(eventId, placedOrder.orderNumber, normalizedBuyer, normalizedGuests))
    } else {
      return dispatch(placeOrder(eventId, normalizedBuyer, normalizedGuests))
    }
  }
}

const setBuyerDetails = (buyerDetails: RegFormData) => ({
  type: SET_BUYER_DETAILS,
  payload: {buyerDetails},
})

export const submitCheckoutStep = (data: any) => async (dispatch: Function, getState: GetState) => {
  const state = getState()
  const currentStep = getCurrentStep(state)
  const assignedTicketsEnabled = isAssignedTickets(state.event)
  const placedOrder = state.placedOrder.order
  const buyerDetails = getBuyerDetails(state)
  const ticketsDetails = getTicketsDetails(state)
  const reservationId = getReservationId(state)
  const policies = hasPolicies(state)
  const agreedWithPolicies = hasAgreedWithPolicies(state)
  const template = isTemplate(state)

  switch (currentStep) {
    case FormStep.BuyerDetails: {
      dispatch(setBuyerDetails(data))

      if (template) {
        dispatch(navigateToTemplateOrder())
        break
      }

      if (policies && !agreedWithPolicies) {
        break
      }

      if (!assignedTicketsEnabled || isOrderCompleted(placedOrder)) {
        await dispatch(placeOrderCheckout(data, assignedTicketsEnabled ? ticketsDetails : null))
      }
      break
    }
    case FormStep.TicketsDetails: {
      if (agreedWithPolicies || !policies) {
        await dispatch(placeOrderCheckout({...buyerDetails, reservation: reservationId}, data))
      }
      break
    }
    case FormStep.Policies: {
      await dispatch(placeOrderCheckout(buyerDetails, assignedTicketsEnabled ? ticketsDetails : null))
      break
    }
    default:
      break
  }

  dispatch(handleNextStep())
}

export const handleNextStep = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const assignedTicketsEnabled = isAssignedTickets(state.event)

  if (
    isStepCompleted({
      step: getCurrentStep(state),
      validPaymentAdded: isValidPaymentAdded(state),
      buyerDetails: getBuyerDetails(state),
      placedOrder: state.placedOrder.order,
      assignedTicketsEnabled,
      agreedWithPolicies: hasAgreedWithPolicies(state),
      hasPolicies: hasPolicies(state),
      allTicketDetailsValid: isAllTicketDetailsValid(state),
    })
  ) {
    dispatch(nextStep())
  }
}

export const editStep = (currentStep: FormStep, validPaymentAdded: boolean) => ({
  type: EDIT_STEP,
  payload: {
    currentStep,
    validPaymentAdded,
  },
})

const nextStep = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const {validPaymentAdded, buyerDetails} = state.checkout
  const assignedTicketsEnabled = isAssignedTickets(state.event)

  dispatch({
    type: NEXT_STEP,
    payload: {
      nextStep: CHECKOUT_STEPS.find(step => {
        const isVisible = isStepVisible({
          step,
          assignedTicketsEnabled,
          invoice: getInvoice(state),
          hasPolicies: hasPolicies(state),
          isTemplate: isTemplate(state),
        })
        const isCompleted = isStepCompleted({
          step,
          validPaymentAdded,
          buyerDetails,
          placedOrder: state.placedOrder.order,
          assignedTicketsEnabled,
          agreedWithPolicies: hasAgreedWithPolicies(state),
          hasPolicies: hasPolicies(state),
          allTicketDetailsValid: isAllTicketDetailsValid(state),
        })

        return isVisible && !isCompleted
      }),
    },
  })
}

export const clearCheckout = () => ({
  type: CLEAR_CHECKOUT,
})

const normalizeFormData = (formData = {} as RegFormData) => {
  const normalizedFormData = {
    ...formData,
  }

  if (formData.email) {
    normalizedFormData.email = formData.email.trim()
  }

  if (formData.additionalGuests) {
    normalizedFormData.guestNames = formData.additionalGuests.guestNames.map(
      ({firstName, lastName}: any) => `${firstName} ${lastName}`,
    )
    normalizedFormData.additionalGuests = formData.additionalGuests.additionalGuests
  }

  return normalizedFormData
}

export const setUseBuyerDetails = (index: number, useBuyerDetails: boolean) => ({
  type: USE_BUYER_DETAILS,
  payload: {index, useBuyerDetails},
})

export const setTicketDetails = (index: number, details: RegFormData, valid: boolean) => ({
  type: SET_TICKET_DETAILS,
  payload: {index, details, valid},
})
