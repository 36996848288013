import * as React from 'react'
import {ERROR} from '../../../../actions/seating'
import {SeatsUnavailable} from './seats-unavailable'
import {SomethingWentWrong} from './something-went-wrong'
import {TicketLimitReached} from './ticket-limit-reached'

export const ErrorModal = ({type}: {type: ERROR}) => {
  switch (type) {
    case ERROR.TICKET_LIMIT_REACHED:
      return <TicketLimitReached />
    case ERROR.SOMETHING_WENT_WRONG:
      return <SomethingWentWrong />
    case ERROR.SEATS_UNAVAILABLE:
      return <SeatsUnavailable />
    case ERROR.NO_ERROR:
    default:
      return null
  }
}
