import {isTicketLimitReached} from '../../../../../selectors/seating'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {TicketsListOwnProps, TicketsListRuntimeProps} from './interfaces'
import {TicketsList as Presentation} from './tickets-list'

const mapRuntime = ({state, actions: {addPlaceToBasket, setError}}: DetailsPageAppProps): TicketsListRuntimeProps => ({
  showAccessibilityMode: state.seating.showAccessibilityMode,
  addPlaceToBasket,
  setError,
  ticketLimitReached: isTicketLimitReached(state),
})

export const TicketsList = connect<TicketsListOwnProps, TicketsListRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
