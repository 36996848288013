import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import classNames from 'classnames'
import {Button} from 'wix-ui-tpa/Button'
import {BUTTON_PRIORITY} from 'wix-ui-tpa/SectionNotification'
import {ERROR} from '../../../../../actions/seating'
import {classes} from '../error-modal.st.css'
import {SeatsUnavailableProps} from './interfaces'

export const SeatsUnavailable = ({setError}: SeatsUnavailableProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  return (
    <div className={classes.container}>
      <div className={classNames(classes.content, {[classes.desktopContent]: !isMobile})}>
        <div className={classes.text}>
          <Text className={classNames(classes.title, {[classes.mobileTitle]: isMobile})}>
            {t('seatings_seatsUnavailableModal_title')}
          </Text>
          <Text className={classes.subtitle}>{t('seatings_seatsUnavailableModal_subtitle')}</Text>
        </div>
        <Button priority={BUTTON_PRIORITY.primary} onClick={() => setError(ERROR.NO_ERROR)}>
          {t('seatings_seatsUnavailableModal_button')}
        </Button>
      </div>
    </div>
  )
}
