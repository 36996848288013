import {withTranslation} from '@wix/yoshi-flow-editor'
import {getEventTitle} from '@wix/wix-events-commons-statics'
import {connect} from '../runtime-context/context'
import {getGroupImage, getGroupTitle, getMembersCount, getUpdatesCount} from '../../selectors/groups'
import {DetailsPageAppProps} from '../app/interfaces'
import {GroupCardRuntimeProps, GroupCardOwnProps} from './interfaces'
import {GroupCard as Presentation} from './group-card'

export const mapRuntime = ({state, actions: {joinGroup}}: DetailsPageAppProps): GroupCardRuntimeProps => ({
  eventTitle: getEventTitle(state.event),
  title: getGroupTitle(state),
  image: getGroupImage(state),
  membersCount: getMembersCount(state),
  updatesCount: getUpdatesCount(state),
  joinGroup,
})

export const GroupCard = withTranslation()(connect<GroupCardOwnProps, GroupCardRuntimeProps>(mapRuntime)(Presentation))
