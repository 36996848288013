import * as React from 'react'
import classNames from 'classnames'
import {Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {TicketInfo} from '../ticket-info'
import {classes} from './ticket.st.css'
import {TicketAction} from './ticket-action'
import {TicketProps} from '.'

export const Ticket = ({
  place,
  selected,
  lastTicket,
  inDescription,
  showAccessibilityMode,
  onRemoveClick,
  onClick,
  onTicketMoreInfoClick,
  setForcedPopover,
}: TicketProps) => {
  const area = place?.elementType === Type.AREA

  const handleRemove = () => onRemoveClick(place)

  const handleKeyDown: React.KeyboardEventHandler<HTMLLIElement> = event => {
    if (event.key === 'Enter') {
      onClick?.(place)
    }
    event.stopPropagation()
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement | HTMLLIElement> = event => {
    event.stopPropagation()
    onClick?.(place)
  }

  return (
    <li
      className={classNames(classes.ticket, {[classes.lastTicket]: lastTicket, [classes.selected]: selected})}
      onClick={area || inDescription ? undefined : handleClick}
      onKeyDown={area || inDescription ? undefined : handleKeyDown}
      onMouseEnter={() => setForcedPopover(place.id)}
      onMouseLeave={() => setForcedPopover(null)}
      tabIndex={inDescription ? undefined : 0}
      data-hook={`ticket-${place?.id}`}
    >
      <div className={classes.color} style={{backgroundColor: place?.color}} />
      <TicketInfo place={place} onRemoveClick={onRemoveClick ? handleRemove : undefined} selected={selected} />
      <TicketAction
        area={area}
        ticketDescription={place?.ticketDescription}
        selected={selected}
        showAccessibilityMode={showAccessibilityMode}
        inDescription={inDescription}
        onClick={event => (showAccessibilityMode ? handleClick(event) : onTicketMoreInfoClick(place))}
      />
    </li>
  )
}
