import {GetState, StoreExtraArgs} from '../types'
import {callAPI, createActions} from '../../../commons/actions/data-action-helper'
import {navigateToGroup} from '../../../commons/services/navigation'
import {getGroupId, getGroupSlug} from '../selectors/groups'

export const GET_CONNECTED_GROUP = createActions('GET_CONNECTED_GROUP')
export const GET_GROUP_ACTIVITY = createActions('GET_GROUP_ACTIVITY')
export const JOIN_GROUP = 'JOIN_GROUP'

export const getConnectedGroup = () => async (dispatch: Function, getState: GetState) => {
  const response = await dispatch(callAPI(GET_CONNECTED_GROUP, getState().event.id))
  if (response && response.group?.id) {
    await dispatch(getGroupActivity({groupId: response.group.id}))
  }
}

export const getGroupActivity = ({groupId}: {groupId: string}) => async (dispatch: Function) => {
  await dispatch(callAPI(GET_GROUP_ACTIVITY, groupId))
}

export const joinGroup = () => async (dispatch, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
  dispatch({type: JOIN_GROUP})
  const state = getState()
  const groupId = getGroupId(state)
  const slug = getGroupSlug(state)
  await navigateToGroup(slug, groupId, wixCodeApi)
}
