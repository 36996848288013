import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import s from '../policy-checkbox.scss'
import {PolicyListProps} from './interfaces'

export const PolicyList = ({policies, onPolicyClick}: PolicyListProps) => {
  return (
    <>
      {policies.map((policy, index) => {
        const last = policies.length - 1 === index
        return (
          <>
            <a
              data-hook={DH.POLICY}
              className={s.policy}
              href="#"
              onClick={event => {
                event.preventDefault()
                onPolicyClick(event, policy.id, `[data-hook="${DH.POLICY}"][data-index="${index}"]`)
              }}
              data-index={index}
              tabIndex={0}
            >
              {policy.name}
              {last ? '' : ','}
            </a>
            {last ? '' : ' '}
          </>
        )
      })}
    </>
  )
}
