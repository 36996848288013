import {getTaxConfig, getTicketServiceFee, getTicketTax, isTaxAdded} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {isWixFeeTypeIsAdded} from './tickets'

export const getTax = (event: ExtendedEvent, ticket: wix.events.ticketing.TicketDefinition, t: TFunction) => {
  const taxConfig = getTaxConfig(event)
  const visible = Boolean(taxConfig) && !ticket.free

  if (!visible) {
    return null
  }

  return isTaxAdded(taxConfig.type)
    ? `+${getTicketTax(ticket, taxConfig)} ${taxConfig.name}`
    : t('tax.included', taxConfig)
}

export const getFee = (event: ExtendedEvent, ticket: wix.events.ticketing.TicketDefinition, t: TFunction) => {
  const taxConfig = getTaxConfig(event)

  if (!isWixFeeTypeIsAdded(ticket) || ticket.free) {
    return null
  }

  return t('checkout_plusServiceFee', {fee: getTicketServiceFee(ticket, taxConfig)})
}
