import React from 'react'
import {Provider} from 'react-redux'
import {combineReducers, createStore} from 'redux'
import {reducer as form, getFormSyncErrors} from 'redux-form'
import {RegFormData} from '../../types'
import {FormOwnProps} from './interfaces'
import {isFormValid} from './utils'
import {Form as FormComponent} from '.'

export class Form extends React.Component<FormOwnProps & {initialValues: Partial<RegFormData>; form?: string}> {
  store = createStore(combineReducers({form}))

  shouldComponentUpdate(props) {
    const ignoredKeys = ['initialValues']
    return !!Object.keys(props)
      .filter(key => !ignoredKeys.includes(key))
      .find(key => this.props[key] !== props[key])
  }

  onChange = (values: RegFormData) => {
    if (!this.props.isTicketForm) {
      return null
    }

    const errors = getFormSyncErrors(this.props.form)(this.store.getState())
    const formIndex = this.props.form.match(/\d+/)?.[0]
    const valid = isFormValid(errors)

    typeof formIndex !== 'undefined' && this.props.setTicketDetails(Number(formIndex), values, valid)
  }

  render() {
    return (
      <Provider store={this.store}>
        <FormComponent {...this.props} onChange={this.onChange} />
      </Provider>
    )
  }
}
