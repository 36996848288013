import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {isChooseSeatMode} from '../../../../selectors/navigation'
import {isTicketLimitReached} from '../../../../selectors/seating'
import {TicketInfoRuntimeProps, TicketInfoOwnProps} from './interfaces'
import {TicketInfo as Presentation} from './ticket-info'

const mapRuntime = ({state, actions: {addPlaceToBasket, setError}}: DetailsPageAppProps): TicketInfoRuntimeProps => ({
  selectedTickets: state.selectedTickets,
  showAccessibilityMode: state.seating.showAccessibilityMode,
  chooseSeatMode: isChooseSeatMode(state),
  addPlaceToBasket,
  ticketLimitReached: isTicketLimitReached(state),
  setError,
})

export const TicketInfo = connect<TicketInfoOwnProps, TicketInfoRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
