import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {calculateSubPrice} from '../../../../../services/payment'
import {TableRow} from '../../../../table-row'
import {LabelWithValue} from '../../../../common/label-with-value'
import c from '../../../../classnames.scss'
import {getPlaceInfoFromTicket, getTicketPriceText} from '../../../../../selectors/order-success'
import s from './item.scss'
import {ItemProps} from '.'

export const Item = ({item, t, hasSeatingPlan}: ItemProps) => {
  const placeInfoClassNames = classNames(c.formLabelsColor, c.evTextFont)
  const {area, table} = getPlaceInfoFromTicket(item.ticket)

  const columns = hasSeatingPlan
    ? [
        {content: item.name, dataHook: DH.TICKET_NAME},
        {
          content: (
            <div className={s.seatInfo}>
              <LabelWithValue
                label={t('seatings_sector')}
                value={item.ticket.ticketDetails.sectorName}
                valueClassName={placeInfoClassNames}
                labelClassName={placeInfoClassNames}
              />
              {area ? (
                <LabelWithValue
                  label={t('seatings_area')}
                  value={area}
                  valueClassName={placeInfoClassNames}
                  labelClassName={placeInfoClassNames}
                />
              ) : (
                <>
                  <LabelWithValue
                    label={table ? t('seatings_table') : t('seatings_row')}
                    value={table || item.ticket.ticketDetails.rowNumber}
                    valueClassName={placeInfoClassNames}
                    labelClassName={placeInfoClassNames}
                  />
                  <LabelWithValue
                    label={t('seatings_seat')}
                    value={item.ticket.ticketDetails.seatNumber}
                    valueClassName={placeInfoClassNames}
                    labelClassName={placeInfoClassNames}
                  />
                </>
              )}
            </div>
          ),
          className: s.seatInfoColumn,
        },
        {content: ''},
        {content: getTicketPriceText(item.price, t), dataHook: DH.TICKET_PRICE},
      ]
    : [
        {content: item.name, dataHook: DH.TICKET_NAME},
        {content: getTicketPriceText(item.price, t), dataHook: DH.TICKET_PRICE},
        {content: item.quantity, dataHook: DH.TICKET_QUANTITY},
        {
          content: getTicketPriceText(
            {
              amount: calculateSubPrice(Number(item.price.amount), item.quantity).toString(),
              currency: item.price.currency,
            },
            t,
          ),
          dataHook: DH.TICKET_TOTAL_PRICE,
        },
      ]

  return (
    <div data-hook={DH.ORDER_SUCCESS_SUMMARY_WRAPPER}>
      <TableRow
        config={{
          dataHook: DH.ORDER_SUCCESS_SUMMARY,
          itemKey: item.id,
          columns,
          className: hasSeatingPlan ? s.row : undefined,
        }}
      />
    </div>
  )
}
