import {withTranslation} from '@wix/yoshi-flow-editor'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {isChooseSeatMode} from '../../../selectors/navigation'
import {getSelectedPlace, getSelectedTicketsWithPlaces, isTicketLimitReached} from '../../../selectors/seating'
import {SeatingPlanRuntimeProps} from './interfaces'
import {SeatingPlan as Presentation} from './seating-plan'

const mapRuntime = ({
  state: {
    seating: {loading, showAccessibilityMode, error},
  },
  state,
  actions: {checkout, setShowAccessibilityMode, addPlaceToBasket, selectPlace, unselectPlace, setError},
}: DetailsPageAppProps): SeatingPlanRuntimeProps => ({
  addPlaceToBasket,
  checkout,
  loading,
  error,
  ticketLimitReached: isTicketLimitReached(state),
  placesInBasket: getSelectedTicketsWithPlaces(state),
  showAccessibilityMode,
  chooseSeatMode: isChooseSeatMode(state),
  setShowAccessibilityMode,
  selectedPlace: getSelectedPlace(state),
  unselectPlace,
  selectPlace,
  setError,
})

export const SeatingPlanModal = connect<{}, SeatingPlanRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
export * from './interfaces'
