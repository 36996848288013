import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {AccessibilityModeButtonRuntimeProps} from './interfaces'
import {AccessibilityModeButton as Presentation} from './accessibility-mode-button'

const mapRuntime = ({
  actions: {setShowAccessibilityMode},
}: DetailsPageAppProps): AccessibilityModeButtonRuntimeProps => ({
  setShowAccessibilityMode,
})

export const AccessibilityModeButton = connect<{}, AccessibilityModeButtonRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
