import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import classNames from 'classnames'
import {Button} from 'wix-ui-tpa/Button'
import {BUTTON_PRIORITY} from 'wix-ui-tpa/SectionNotification'
import {ERROR} from '../../../../../actions/seating'
import {classes} from '../error-modal.st.css'
import {SomethingWentWrongProps} from './interfaces'

export const SomethingWentWrong = ({setError, tryAgainClick}: SomethingWentWrongProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  return (
    <div className={classes.container}>
      <div className={classNames(classes.content, {[classes.desktopContent]: !isMobile})}>
        <div className={classes.text}>
          <Text className={classNames(classes.title, {[classes.mobileTitle]: isMobile})}>
            {t('seatings_somethingWentWrongModal_title')}
          </Text>
        </div>
        <div className={classes.buttonContainer}>
          <Button priority={BUTTON_PRIORITY.secondary} onClick={() => setError(ERROR.NO_ERROR)}>
            {t('seatings_somethingWentWrongModal_closeButton')}
          </Button>
          <Button priority={BUTTON_PRIORITY.primary} onClick={() => tryAgainClick()}>
            {t('seatings_somethingWentWrongModal_tryAgainButton')}
          </Button>
        </div>
      </div>
    </div>
  )
}
