import React from 'react'
import classNames from 'classnames'
import {Divider} from '../../common/divider'
import {OrderSuccessProps} from '../index'
import c from '../../../classnames.scss'
import {Invoice} from './invoice'
import s from './mobile.scss'

export const Mobile = ({
  order: {orderNumber, invoice, tickets},
  formattedOrderCreatedDate,
  t,
  navigateToMainPage,
  hasSeatingPlan,
}: OrderSuccessProps) => (
  <div className={classNames(c.formLabelsColor, c.evTextFont)}>
    <div className={s.header}>
      <div className={s.orderNumber}>{t('ticketsThankYou.orderNo', {orderNumber})}</div>
    </div>
    <Divider />
    <div className={s.placedOn}>{t('ticketsThankYou.placedOn', {date: formattedOrderCreatedDate})}</div>
    <Divider />
    <Invoice invoice={invoice} tickets={tickets} hasSeatingPlan={hasSeatingPlan} />
    <Divider />
    <button className={s.navigate} onClick={() => navigateToMainPage()}>
      {t('backToSite')}
    </button>
  </div>
)
