import * as React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import BulletedListSmall from 'wix-ui-icons-common/on-stage/BulletedListSmall'
import {TextButton, TEXT_BUTTON_PRIORITY} from 'wix-ui-tpa/TextButton'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {classes} from './accessibility-mode-button.st.css'
import {AccessibilityModeButtonProps} from '.'

export const AccessibilityModeButton = ({setShowAccessibilityMode}: AccessibilityModeButtonProps) => {
  const {t} = useTranslation()
  return (
    <div className={classes.accessibilityModeContainer}>
      <TextButton
        className={classes.textButton}
        data-hook={DH.ACCESSIBILITY_MODE_BUTTON}
        priority={TEXT_BUTTON_PRIORITY.secondary}
        prefixIcon={<BulletedListSmall />}
        onClick={() => setShowAccessibilityMode(true)}
      >
        {t('seatings_selectFromList')}
      </TextButton>
    </div>
  )
}
