import React from 'react'
import {RsvpStatus} from '@wix/events-types'
import {FormStep, RSVP_STEPS} from '../../../constants/constants'
import {isStepCompleted, isStepVisible} from '../../../selectors/rsvp'
import {BuyerDetails} from '../../buyer-details'
import {Policies} from '../../policies'
import {RsvpFormContainerProps} from '.'

export const RsvpFormContainer = ({
  rsvpDetails,
  agreedWithPolicies,
  hasPolicies,
  t,
  editRsvpStep,
  messages: {
    rsvp: {positiveMessages, negativeMessages, waitlistMessages},
  },
  response,
  submitRsvpStep,
  children,
  currentStep,
}: RsvpFormContainerProps) => {
  const getSteps = () => {
    return RSVP_STEPS.filter(step => isStepVisible({step, hasPolicies})).map(step => ({
      key: step,
      title: step === FormStep.BuyerDetails ? getTitle() : t(`mobile.checkout.step.${step}`),
      completed: isStepCompleted({
        step,
        rsvpDetails,
        agreedWithPolicies,
      }),
      renderStep: renderStep(step),
      onEdit: editRsvpStep,
    }))
  }

  const getTitle = () => {
    if (response === RsvpStatus.YES) {
      return positiveMessages.title
    } else if (response === RsvpStatus.WAITING) {
      return waitlistMessages.title
    } else {
      return negativeMessages.title
    }
  }

  const renderStep = (step: string) => {
    const props = {
      onSubmit: (data: any) => submitRsvpStep(data),
      t,
    }

    switch (step) {
      case FormStep.BuyerDetails:
        return (editing: boolean) => <BuyerDetails editing={editing} {...props} />
      case FormStep.Policies:
        return (editing: boolean) => <Policies editing={editing} {...props} />
      default:
        return () => null
    }
  }

  return children(getSteps(), currentStep)
}
