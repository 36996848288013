import classNames from 'classnames'
import React from 'react'
import ChevronDown from 'wix-ui-icons-common/ChevronDown'
import ChevronUp from 'wix-ui-icons-common/ChevronUp'
import c from '../classnames.scss'
import {SummaryProps} from './interfaces'
import {SummaryDivider} from './summary-divider'
import s from './summary.scss'
import {TicketsSummary} from './tickets-summary'

export const Summary = ({title, date, location, ticketedEvent}: SummaryProps) => {
  const [expanded, setExpanded] = React.useState(false)

  return (
    <div
      className={classNames(s.summary, c.formSummaryBox, c.formSummaryColor, {[s.expanded]: expanded})}
      id="event-summary"
    >
      <div className={s.container}>
        <div className={s.info}>
          <div className={classNames(s.title, c.formSummaryTitle)} data-hook="event-title">
            {title}
          </div>
          <SummaryDivider />
          <div className={c.formSummaryBody}>
            <div data-hook="event-time">{date}</div>
            <div data-hook="event-location">{location}</div>
          </div>
        </div>
        {ticketedEvent && (
          <div className={s.expandButton} onClick={() => setExpanded(!expanded)}>
            {expanded ? <ChevronUp /> : <ChevronDown />}
          </div>
        )}
      </div>
      {ticketedEvent && <TicketsSummary />}
    </div>
  )
}
